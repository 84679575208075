// source: scoretrak/team/v1/team.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var scoretrak_proto_v1_uuid_pb = require('../../../scoretrak/proto/v1/uuid_pb.js');
goog.object.extend(proto, scoretrak_proto_v1_uuid_pb);
var scoretrak_host_v1_host_pb = require('../../../scoretrak/host/v1/host_pb.js');
goog.object.extend(proto, scoretrak_host_v1_host_pb);
var scoretrak_user_v1_user_pb = require('../../../scoretrak/user/v1/user_pb.js');
goog.object.extend(proto, scoretrak_user_v1_user_pb);
goog.exportSymbol('proto.scoretrak.team.v1.DeleteRequest', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.DeleteResponse', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.GetAllRequest', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.GetAllResponse', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.GetByIDRequest', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.GetByIDResponse', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.StoreRequest', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.StoreResponse', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.Team', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.UpdateRequest', null, global);
goog.exportSymbol('proto.scoretrak.team.v1.UpdateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.Team = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.team.v1.Team.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.team.v1.Team, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.Team.displayName = 'proto.scoretrak.team.v1.Team';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.GetAllRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.team.v1.GetAllRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.GetAllRequest.displayName = 'proto.scoretrak.team.v1.GetAllRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.GetAllResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.team.v1.GetAllResponse.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.team.v1.GetAllResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.GetAllResponse.displayName = 'proto.scoretrak.team.v1.GetAllResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.GetByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.team.v1.GetByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.GetByIDRequest.displayName = 'proto.scoretrak.team.v1.GetByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.GetByIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.team.v1.GetByIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.GetByIDResponse.displayName = 'proto.scoretrak.team.v1.GetByIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.DeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.team.v1.DeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.DeleteRequest.displayName = 'proto.scoretrak.team.v1.DeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.DeleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.team.v1.DeleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.DeleteResponse.displayName = 'proto.scoretrak.team.v1.DeleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.StoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.team.v1.StoreRequest.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.team.v1.StoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.StoreRequest.displayName = 'proto.scoretrak.team.v1.StoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.StoreResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.team.v1.StoreResponse.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.team.v1.StoreResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.StoreResponse.displayName = 'proto.scoretrak.team.v1.StoreResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.UpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.team.v1.UpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.UpdateRequest.displayName = 'proto.scoretrak.team.v1.UpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.team.v1.UpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.team.v1.UpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.team.v1.UpdateResponse.displayName = 'proto.scoretrak.team.v1.UpdateResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.team.v1.Team.repeatedFields_ = [5,7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.Team.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.Team.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.Team} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.Team.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    hide: (f = msg.getHide()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    pause: (f = msg.getPause()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    hostsList: jspb.Message.toObjectList(msg.getHostsList(),
    scoretrak_host_v1_host_pb.Host.toObject, includeInstance),
    index: (f = msg.getIndex()) && google_protobuf_wrappers_pb.UInt64Value.toObject(includeInstance, f),
    usersList: jspb.Message.toObjectList(msg.getUsersList(),
    scoretrak_user_v1_user_pb.User.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.Team}
 */
proto.scoretrak.team.v1.Team.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.Team;
  return proto.scoretrak.team.v1.Team.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.Team} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.Team}
 */
proto.scoretrak.team.v1.Team.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHide(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setPause(value);
      break;
    case 5:
      var value = new scoretrak_host_v1_host_pb.Host;
      reader.readMessage(value,scoretrak_host_v1_host_pb.Host.deserializeBinaryFromReader);
      msg.addHosts(value);
      break;
    case 6:
      var value = new google_protobuf_wrappers_pb.UInt64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt64Value.deserializeBinaryFromReader);
      msg.setIndex(value);
      break;
    case 7:
      var value = new scoretrak_user_v1_user_pb.User;
      reader.readMessage(value,scoretrak_user_v1_user_pb.User.deserializeBinaryFromReader);
      msg.addUsers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.Team.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.Team.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.Team} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.Team.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getHide();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPause();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getHostsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      5,
      f,
      scoretrak_host_v1_host_pb.Host.serializeBinaryToWriter
    );
  }
  f = message.getIndex();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      google_protobuf_wrappers_pb.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getUsersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      scoretrak_user_v1_user_pb.User.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.team.v1.Team.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.team.v1.Team} returns this
*/
proto.scoretrak.team.v1.Team.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.team.v1.Team} returns this
 */
proto.scoretrak.team.v1.Team.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.team.v1.Team.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.scoretrak.team.v1.Team.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scoretrak.team.v1.Team} returns this
 */
proto.scoretrak.team.v1.Team.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional google.protobuf.BoolValue hide = 3;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.scoretrak.team.v1.Team.prototype.getHide = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 3));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.scoretrak.team.v1.Team} returns this
*/
proto.scoretrak.team.v1.Team.prototype.setHide = function(value) {
  return jspb.Message.setWrapperField(this, 3, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.team.v1.Team} returns this
 */
proto.scoretrak.team.v1.Team.prototype.clearHide = function() {
  return this.setHide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.team.v1.Team.prototype.hasHide = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional google.protobuf.BoolValue pause = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.scoretrak.team.v1.Team.prototype.getPause = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.scoretrak.team.v1.Team} returns this
*/
proto.scoretrak.team.v1.Team.prototype.setPause = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.team.v1.Team} returns this
 */
proto.scoretrak.team.v1.Team.prototype.clearPause = function() {
  return this.setPause(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.team.v1.Team.prototype.hasPause = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * repeated scoretrak.host.v1.Host hosts = 5;
 * @return {!Array<!proto.scoretrak.host.v1.Host>}
 */
proto.scoretrak.team.v1.Team.prototype.getHostsList = function() {
  return /** @type{!Array<!proto.scoretrak.host.v1.Host>} */ (
    jspb.Message.getRepeatedWrapperField(this, scoretrak_host_v1_host_pb.Host, 5));
};


/**
 * @param {!Array<!proto.scoretrak.host.v1.Host>} value
 * @return {!proto.scoretrak.team.v1.Team} returns this
*/
proto.scoretrak.team.v1.Team.prototype.setHostsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 5, value);
};


/**
 * @param {!proto.scoretrak.host.v1.Host=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.host.v1.Host}
 */
proto.scoretrak.team.v1.Team.prototype.addHosts = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 5, opt_value, proto.scoretrak.host.v1.Host, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.team.v1.Team} returns this
 */
proto.scoretrak.team.v1.Team.prototype.clearHostsList = function() {
  return this.setHostsList([]);
};


/**
 * optional google.protobuf.UInt64Value index = 6;
 * @return {?proto.google.protobuf.UInt64Value}
 */
proto.scoretrak.team.v1.Team.prototype.getIndex = function() {
  return /** @type{?proto.google.protobuf.UInt64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt64Value, 6));
};


/**
 * @param {?proto.google.protobuf.UInt64Value|undefined} value
 * @return {!proto.scoretrak.team.v1.Team} returns this
*/
proto.scoretrak.team.v1.Team.prototype.setIndex = function(value) {
  return jspb.Message.setWrapperField(this, 6, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.team.v1.Team} returns this
 */
proto.scoretrak.team.v1.Team.prototype.clearIndex = function() {
  return this.setIndex(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.team.v1.Team.prototype.hasIndex = function() {
  return jspb.Message.getField(this, 6) != null;
};


/**
 * repeated scoretrak.user.v1.User users = 7;
 * @return {!Array<!proto.scoretrak.user.v1.User>}
 */
proto.scoretrak.team.v1.Team.prototype.getUsersList = function() {
  return /** @type{!Array<!proto.scoretrak.user.v1.User>} */ (
    jspb.Message.getRepeatedWrapperField(this, scoretrak_user_v1_user_pb.User, 7));
};


/**
 * @param {!Array<!proto.scoretrak.user.v1.User>} value
 * @return {!proto.scoretrak.team.v1.Team} returns this
*/
proto.scoretrak.team.v1.Team.prototype.setUsersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.scoretrak.user.v1.User=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.user.v1.User}
 */
proto.scoretrak.team.v1.Team.prototype.addUsers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.scoretrak.user.v1.User, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.team.v1.Team} returns this
 */
proto.scoretrak.team.v1.Team.prototype.clearUsersList = function() {
  return this.setUsersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.GetAllRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.GetAllRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.GetAllRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.GetAllRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.GetAllRequest}
 */
proto.scoretrak.team.v1.GetAllRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.GetAllRequest;
  return proto.scoretrak.team.v1.GetAllRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.GetAllRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.GetAllRequest}
 */
proto.scoretrak.team.v1.GetAllRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.GetAllRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.GetAllRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.GetAllRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.GetAllRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.team.v1.GetAllResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.GetAllResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.GetAllResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.GetAllResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.GetAllResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.scoretrak.team.v1.Team.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.GetAllResponse}
 */
proto.scoretrak.team.v1.GetAllResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.GetAllResponse;
  return proto.scoretrak.team.v1.GetAllResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.GetAllResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.GetAllResponse}
 */
proto.scoretrak.team.v1.GetAllResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.team.v1.Team;
      reader.readMessage(value,proto.scoretrak.team.v1.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.GetAllResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.GetAllResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.GetAllResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.GetAllResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.scoretrak.team.v1.Team.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Team teams = 1;
 * @return {!Array<!proto.scoretrak.team.v1.Team>}
 */
proto.scoretrak.team.v1.GetAllResponse.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.scoretrak.team.v1.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scoretrak.team.v1.Team, 1));
};


/**
 * @param {!Array<!proto.scoretrak.team.v1.Team>} value
 * @return {!proto.scoretrak.team.v1.GetAllResponse} returns this
*/
proto.scoretrak.team.v1.GetAllResponse.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scoretrak.team.v1.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.team.v1.Team}
 */
proto.scoretrak.team.v1.GetAllResponse.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scoretrak.team.v1.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.team.v1.GetAllResponse} returns this
 */
proto.scoretrak.team.v1.GetAllResponse.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.GetByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.GetByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.GetByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.GetByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.GetByIDRequest}
 */
proto.scoretrak.team.v1.GetByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.GetByIDRequest;
  return proto.scoretrak.team.v1.GetByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.GetByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.GetByIDRequest}
 */
proto.scoretrak.team.v1.GetByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.GetByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.GetByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.GetByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.GetByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.team.v1.GetByIDRequest.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.team.v1.GetByIDRequest} returns this
*/
proto.scoretrak.team.v1.GetByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.team.v1.GetByIDRequest} returns this
 */
proto.scoretrak.team.v1.GetByIDRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.team.v1.GetByIDRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.GetByIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.GetByIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.GetByIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.GetByIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    team: (f = msg.getTeam()) && proto.scoretrak.team.v1.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.GetByIDResponse}
 */
proto.scoretrak.team.v1.GetByIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.GetByIDResponse;
  return proto.scoretrak.team.v1.GetByIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.GetByIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.GetByIDResponse}
 */
proto.scoretrak.team.v1.GetByIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.team.v1.Team;
      reader.readMessage(value,proto.scoretrak.team.v1.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.GetByIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.GetByIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.GetByIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.GetByIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.scoretrak.team.v1.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional Team team = 1;
 * @return {?proto.scoretrak.team.v1.Team}
 */
proto.scoretrak.team.v1.GetByIDResponse.prototype.getTeam = function() {
  return /** @type{?proto.scoretrak.team.v1.Team} */ (
    jspb.Message.getWrapperField(this, proto.scoretrak.team.v1.Team, 1));
};


/**
 * @param {?proto.scoretrak.team.v1.Team|undefined} value
 * @return {!proto.scoretrak.team.v1.GetByIDResponse} returns this
*/
proto.scoretrak.team.v1.GetByIDResponse.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.team.v1.GetByIDResponse} returns this
 */
proto.scoretrak.team.v1.GetByIDResponse.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.team.v1.GetByIDResponse.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.DeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.DeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.DeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.DeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.DeleteRequest}
 */
proto.scoretrak.team.v1.DeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.DeleteRequest;
  return proto.scoretrak.team.v1.DeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.DeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.DeleteRequest}
 */
proto.scoretrak.team.v1.DeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.DeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.DeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.DeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.DeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.team.v1.DeleteRequest.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.team.v1.DeleteRequest} returns this
*/
proto.scoretrak.team.v1.DeleteRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.team.v1.DeleteRequest} returns this
 */
proto.scoretrak.team.v1.DeleteRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.team.v1.DeleteRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.DeleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.DeleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.DeleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.DeleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.DeleteResponse}
 */
proto.scoretrak.team.v1.DeleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.DeleteResponse;
  return proto.scoretrak.team.v1.DeleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.DeleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.DeleteResponse}
 */
proto.scoretrak.team.v1.DeleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.DeleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.DeleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.DeleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.DeleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.team.v1.StoreRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.StoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.StoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.StoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.StoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    teamsList: jspb.Message.toObjectList(msg.getTeamsList(),
    proto.scoretrak.team.v1.Team.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.StoreRequest}
 */
proto.scoretrak.team.v1.StoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.StoreRequest;
  return proto.scoretrak.team.v1.StoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.StoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.StoreRequest}
 */
proto.scoretrak.team.v1.StoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.team.v1.Team;
      reader.readMessage(value,proto.scoretrak.team.v1.Team.deserializeBinaryFromReader);
      msg.addTeams(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.StoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.StoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.StoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.StoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeamsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.scoretrak.team.v1.Team.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Team teams = 1;
 * @return {!Array<!proto.scoretrak.team.v1.Team>}
 */
proto.scoretrak.team.v1.StoreRequest.prototype.getTeamsList = function() {
  return /** @type{!Array<!proto.scoretrak.team.v1.Team>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scoretrak.team.v1.Team, 1));
};


/**
 * @param {!Array<!proto.scoretrak.team.v1.Team>} value
 * @return {!proto.scoretrak.team.v1.StoreRequest} returns this
*/
proto.scoretrak.team.v1.StoreRequest.prototype.setTeamsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scoretrak.team.v1.Team=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.team.v1.Team}
 */
proto.scoretrak.team.v1.StoreRequest.prototype.addTeams = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scoretrak.team.v1.Team, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.team.v1.StoreRequest} returns this
 */
proto.scoretrak.team.v1.StoreRequest.prototype.clearTeamsList = function() {
  return this.setTeamsList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.team.v1.StoreResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.StoreResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.StoreResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.StoreResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.StoreResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    scoretrak_proto_v1_uuid_pb.UUID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.StoreResponse}
 */
proto.scoretrak.team.v1.StoreResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.StoreResponse;
  return proto.scoretrak.team.v1.StoreResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.StoreResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.StoreResponse}
 */
proto.scoretrak.team.v1.StoreResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.StoreResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.StoreResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.StoreResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.StoreResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * repeated scoretrak.proto.v1.UUID ids = 1;
 * @return {!Array<!proto.scoretrak.proto.v1.UUID>}
 */
proto.scoretrak.team.v1.StoreResponse.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.scoretrak.proto.v1.UUID>} */ (
    jspb.Message.getRepeatedWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {!Array<!proto.scoretrak.proto.v1.UUID>} value
 * @return {!proto.scoretrak.team.v1.StoreResponse} returns this
*/
proto.scoretrak.team.v1.StoreResponse.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scoretrak.proto.v1.UUID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.team.v1.StoreResponse.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scoretrak.proto.v1.UUID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.team.v1.StoreResponse} returns this
 */
proto.scoretrak.team.v1.StoreResponse.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.UpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.UpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.UpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.UpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    team: (f = msg.getTeam()) && proto.scoretrak.team.v1.Team.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.UpdateRequest}
 */
proto.scoretrak.team.v1.UpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.UpdateRequest;
  return proto.scoretrak.team.v1.UpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.UpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.UpdateRequest}
 */
proto.scoretrak.team.v1.UpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.team.v1.Team;
      reader.readMessage(value,proto.scoretrak.team.v1.Team.deserializeBinaryFromReader);
      msg.setTeam(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.UpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.UpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.UpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.UpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTeam();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.scoretrak.team.v1.Team.serializeBinaryToWriter
    );
  }
};


/**
 * optional Team team = 1;
 * @return {?proto.scoretrak.team.v1.Team}
 */
proto.scoretrak.team.v1.UpdateRequest.prototype.getTeam = function() {
  return /** @type{?proto.scoretrak.team.v1.Team} */ (
    jspb.Message.getWrapperField(this, proto.scoretrak.team.v1.Team, 1));
};


/**
 * @param {?proto.scoretrak.team.v1.Team|undefined} value
 * @return {!proto.scoretrak.team.v1.UpdateRequest} returns this
*/
proto.scoretrak.team.v1.UpdateRequest.prototype.setTeam = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.team.v1.UpdateRequest} returns this
 */
proto.scoretrak.team.v1.UpdateRequest.prototype.clearTeam = function() {
  return this.setTeam(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.team.v1.UpdateRequest.prototype.hasTeam = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.team.v1.UpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.team.v1.UpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.team.v1.UpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.UpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.team.v1.UpdateResponse}
 */
proto.scoretrak.team.v1.UpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.team.v1.UpdateResponse;
  return proto.scoretrak.team.v1.UpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.team.v1.UpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.team.v1.UpdateResponse}
 */
proto.scoretrak.team.v1.UpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.team.v1.UpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.team.v1.UpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.team.v1.UpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.team.v1.UpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.scoretrak.team.v1);
