// source: scoretrak/service/v1/service.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var scoretrak_proto_v1_uuid_pb = require('../../../scoretrak/proto/v1/uuid_pb.js');
goog.object.extend(proto, scoretrak_proto_v1_uuid_pb);
var scoretrak_property_v1_property_pb = require('../../../scoretrak/property/v1/property_pb.js');
goog.object.extend(proto, scoretrak_property_v1_property_pb);
var scoretrak_check_v1_check_pb = require('../../../scoretrak/check/v1/check_pb.js');
goog.object.extend(proto, scoretrak_check_v1_check_pb);
goog.exportSymbol('proto.scoretrak.service.v1.DeleteRequest', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.DeleteResponse', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.GetAllRequest', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.GetAllResponse', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.GetByIDRequest', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.GetByIDResponse', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.Service', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.StoreRequest', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.StoreResponse', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.TestServiceRequest', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.TestServiceResponse', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.UpdateRequest', null, global);
goog.exportSymbol('proto.scoretrak.service.v1.UpdateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.Service = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.service.v1.Service.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.service.v1.Service, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.Service.displayName = 'proto.scoretrak.service.v1.Service';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.GetAllRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service.v1.GetAllRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.GetAllRequest.displayName = 'proto.scoretrak.service.v1.GetAllRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.GetAllResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.service.v1.GetAllResponse.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.service.v1.GetAllResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.GetAllResponse.displayName = 'proto.scoretrak.service.v1.GetAllResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.GetByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service.v1.GetByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.GetByIDRequest.displayName = 'proto.scoretrak.service.v1.GetByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.GetByIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service.v1.GetByIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.GetByIDResponse.displayName = 'proto.scoretrak.service.v1.GetByIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.DeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service.v1.DeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.DeleteRequest.displayName = 'proto.scoretrak.service.v1.DeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.DeleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service.v1.DeleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.DeleteResponse.displayName = 'proto.scoretrak.service.v1.DeleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.StoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.service.v1.StoreRequest.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.service.v1.StoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.StoreRequest.displayName = 'proto.scoretrak.service.v1.StoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.StoreResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.service.v1.StoreResponse.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.service.v1.StoreResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.StoreResponse.displayName = 'proto.scoretrak.service.v1.StoreResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.UpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service.v1.UpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.UpdateRequest.displayName = 'proto.scoretrak.service.v1.UpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.UpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service.v1.UpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.UpdateResponse.displayName = 'proto.scoretrak.service.v1.UpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.TestServiceRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service.v1.TestServiceRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.TestServiceRequest.displayName = 'proto.scoretrak.service.v1.TestServiceRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service.v1.TestServiceResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service.v1.TestServiceResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service.v1.TestServiceResponse.displayName = 'proto.scoretrak.service.v1.TestServiceResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.service.v1.Service.repeatedFields_ = [12,13];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.Service.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.Service.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.Service} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.Service.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    weight: (f = msg.getWeight()) && google_protobuf_wrappers_pb.UInt64Value.toObject(includeInstance, f),
    pointsBoost: (f = msg.getPointsBoost()) && google_protobuf_wrappers_pb.UInt64Value.toObject(includeInstance, f),
    roundUnits: jspb.Message.getFieldWithDefault(msg, 6, 0),
    roundDelay: (f = msg.getRoundDelay()) && google_protobuf_wrappers_pb.UInt64Value.toObject(includeInstance, f),
    serviceGroupId: (f = msg.getServiceGroupId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f),
    hostId: (f = msg.getHostId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f),
    hide: (f = msg.getHide()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    pause: (f = msg.getPause()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    propertiesList: jspb.Message.toObjectList(msg.getPropertiesList(),
    scoretrak_property_v1_property_pb.Property.toObject, includeInstance),
    checksList: jspb.Message.toObjectList(msg.getChecksList(),
    scoretrak_check_v1_check_pb.Check.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.Service}
 */
proto.scoretrak.service.v1.Service.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.Service;
  return proto.scoretrak.service.v1.Service.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.Service} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.Service}
 */
proto.scoretrak.service.v1.Service.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.UInt64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt64Value.deserializeBinaryFromReader);
      msg.setWeight(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.UInt64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt64Value.deserializeBinaryFromReader);
      msg.setPointsBoost(value);
      break;
    case 6:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRoundUnits(value);
      break;
    case 7:
      var value = new google_protobuf_wrappers_pb.UInt64Value;
      reader.readMessage(value,google_protobuf_wrappers_pb.UInt64Value.deserializeBinaryFromReader);
      msg.setRoundDelay(value);
      break;
    case 8:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setServiceGroupId(value);
      break;
    case 9:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setHostId(value);
      break;
    case 10:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setHide(value);
      break;
    case 11:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setPause(value);
      break;
    case 12:
      var value = new scoretrak_property_v1_property_pb.Property;
      reader.readMessage(value,scoretrak_property_v1_property_pb.Property.deserializeBinaryFromReader);
      msg.addProperties(value);
      break;
    case 13:
      var value = new scoretrak_check_v1_check_pb.Check;
      reader.readMessage(value,scoretrak_check_v1_check_pb.Check.deserializeBinaryFromReader);
      msg.addChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.Service.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.Service.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.Service} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.Service.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getWeight();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getPointsBoost();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getRoundUnits();
  if (f !== 0) {
    writer.writeUint64(
      6,
      f
    );
  }
  f = message.getRoundDelay();
  if (f != null) {
    writer.writeMessage(
      7,
      f,
      google_protobuf_wrappers_pb.UInt64Value.serializeBinaryToWriter
    );
  }
  f = message.getServiceGroupId();
  if (f != null) {
    writer.writeMessage(
      8,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
  f = message.getHostId();
  if (f != null) {
    writer.writeMessage(
      9,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
  f = message.getHide();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPause();
  if (f != null) {
    writer.writeMessage(
      11,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getPropertiesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      12,
      f,
      scoretrak_property_v1_property_pb.Property.serializeBinaryToWriter
    );
  }
  f = message.getChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      13,
      f,
      scoretrak_check_v1_check_pb.Check.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service.v1.Service.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.Service.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.scoretrak.service.v1.Service.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.scoretrak.service.v1.Service.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.UInt64Value weight = 4;
 * @return {?proto.google.protobuf.UInt64Value}
 */
proto.scoretrak.service.v1.Service.prototype.getWeight = function() {
  return /** @type{?proto.google.protobuf.UInt64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt64Value, 4));
};


/**
 * @param {?proto.google.protobuf.UInt64Value|undefined} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setWeight = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearWeight = function() {
  return this.setWeight(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.Service.prototype.hasWeight = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional google.protobuf.UInt64Value points_boost = 5;
 * @return {?proto.google.protobuf.UInt64Value}
 */
proto.scoretrak.service.v1.Service.prototype.getPointsBoost = function() {
  return /** @type{?proto.google.protobuf.UInt64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt64Value, 5));
};


/**
 * @param {?proto.google.protobuf.UInt64Value|undefined} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setPointsBoost = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearPointsBoost = function() {
  return this.setPointsBoost(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.Service.prototype.hasPointsBoost = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional uint64 round_units = 6;
 * @return {number}
 */
proto.scoretrak.service.v1.Service.prototype.getRoundUnits = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 6, 0));
};


/**
 * @param {number} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.setRoundUnits = function(value) {
  return jspb.Message.setProto3IntField(this, 6, value);
};


/**
 * optional google.protobuf.UInt64Value round_delay = 7;
 * @return {?proto.google.protobuf.UInt64Value}
 */
proto.scoretrak.service.v1.Service.prototype.getRoundDelay = function() {
  return /** @type{?proto.google.protobuf.UInt64Value} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.UInt64Value, 7));
};


/**
 * @param {?proto.google.protobuf.UInt64Value|undefined} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setRoundDelay = function(value) {
  return jspb.Message.setWrapperField(this, 7, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearRoundDelay = function() {
  return this.setRoundDelay(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.Service.prototype.hasRoundDelay = function() {
  return jspb.Message.getField(this, 7) != null;
};


/**
 * optional scoretrak.proto.v1.UUID service_group_id = 8;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service.v1.Service.prototype.getServiceGroupId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 8));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setServiceGroupId = function(value) {
  return jspb.Message.setWrapperField(this, 8, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearServiceGroupId = function() {
  return this.setServiceGroupId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.Service.prototype.hasServiceGroupId = function() {
  return jspb.Message.getField(this, 8) != null;
};


/**
 * optional scoretrak.proto.v1.UUID host_id = 9;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service.v1.Service.prototype.getHostId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 9));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setHostId = function(value) {
  return jspb.Message.setWrapperField(this, 9, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearHostId = function() {
  return this.setHostId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.Service.prototype.hasHostId = function() {
  return jspb.Message.getField(this, 9) != null;
};


/**
 * optional google.protobuf.BoolValue hide = 10;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.scoretrak.service.v1.Service.prototype.getHide = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 10));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setHide = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearHide = function() {
  return this.setHide(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.Service.prototype.hasHide = function() {
  return jspb.Message.getField(this, 10) != null;
};


/**
 * optional google.protobuf.BoolValue pause = 11;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.scoretrak.service.v1.Service.prototype.getPause = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 11));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setPause = function(value) {
  return jspb.Message.setWrapperField(this, 11, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearPause = function() {
  return this.setPause(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.Service.prototype.hasPause = function() {
  return jspb.Message.getField(this, 11) != null;
};


/**
 * repeated scoretrak.property.v1.Property properties = 12;
 * @return {!Array<!proto.scoretrak.property.v1.Property>}
 */
proto.scoretrak.service.v1.Service.prototype.getPropertiesList = function() {
  return /** @type{!Array<!proto.scoretrak.property.v1.Property>} */ (
    jspb.Message.getRepeatedWrapperField(this, scoretrak_property_v1_property_pb.Property, 12));
};


/**
 * @param {!Array<!proto.scoretrak.property.v1.Property>} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setPropertiesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 12, value);
};


/**
 * @param {!proto.scoretrak.property.v1.Property=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.property.v1.Property}
 */
proto.scoretrak.service.v1.Service.prototype.addProperties = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 12, opt_value, proto.scoretrak.property.v1.Property, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearPropertiesList = function() {
  return this.setPropertiesList([]);
};


/**
 * repeated scoretrak.check.v1.Check checks = 13;
 * @return {!Array<!proto.scoretrak.check.v1.Check>}
 */
proto.scoretrak.service.v1.Service.prototype.getChecksList = function() {
  return /** @type{!Array<!proto.scoretrak.check.v1.Check>} */ (
    jspb.Message.getRepeatedWrapperField(this, scoretrak_check_v1_check_pb.Check, 13));
};


/**
 * @param {!Array<!proto.scoretrak.check.v1.Check>} value
 * @return {!proto.scoretrak.service.v1.Service} returns this
*/
proto.scoretrak.service.v1.Service.prototype.setChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 13, value);
};


/**
 * @param {!proto.scoretrak.check.v1.Check=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.check.v1.Check}
 */
proto.scoretrak.service.v1.Service.prototype.addChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 13, opt_value, proto.scoretrak.check.v1.Check, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.service.v1.Service} returns this
 */
proto.scoretrak.service.v1.Service.prototype.clearChecksList = function() {
  return this.setChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.GetAllRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.GetAllRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.GetAllRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.GetAllRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.GetAllRequest}
 */
proto.scoretrak.service.v1.GetAllRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.GetAllRequest;
  return proto.scoretrak.service.v1.GetAllRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.GetAllRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.GetAllRequest}
 */
proto.scoretrak.service.v1.GetAllRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.GetAllRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.GetAllRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.GetAllRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.GetAllRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.service.v1.GetAllResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.GetAllResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.GetAllResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.GetAllResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.GetAllResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.scoretrak.service.v1.Service.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.GetAllResponse}
 */
proto.scoretrak.service.v1.GetAllResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.GetAllResponse;
  return proto.scoretrak.service.v1.GetAllResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.GetAllResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.GetAllResponse}
 */
proto.scoretrak.service.v1.GetAllResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.service.v1.Service;
      reader.readMessage(value,proto.scoretrak.service.v1.Service.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.GetAllResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.GetAllResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.GetAllResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.GetAllResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.scoretrak.service.v1.Service.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Service services = 1;
 * @return {!Array<!proto.scoretrak.service.v1.Service>}
 */
proto.scoretrak.service.v1.GetAllResponse.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.scoretrak.service.v1.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scoretrak.service.v1.Service, 1));
};


/**
 * @param {!Array<!proto.scoretrak.service.v1.Service>} value
 * @return {!proto.scoretrak.service.v1.GetAllResponse} returns this
*/
proto.scoretrak.service.v1.GetAllResponse.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scoretrak.service.v1.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.service.v1.Service}
 */
proto.scoretrak.service.v1.GetAllResponse.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scoretrak.service.v1.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.service.v1.GetAllResponse} returns this
 */
proto.scoretrak.service.v1.GetAllResponse.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.GetByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.GetByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.GetByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.GetByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.GetByIDRequest}
 */
proto.scoretrak.service.v1.GetByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.GetByIDRequest;
  return proto.scoretrak.service.v1.GetByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.GetByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.GetByIDRequest}
 */
proto.scoretrak.service.v1.GetByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.GetByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.GetByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.GetByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.GetByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service.v1.GetByIDRequest.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service.v1.GetByIDRequest} returns this
*/
proto.scoretrak.service.v1.GetByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.GetByIDRequest} returns this
 */
proto.scoretrak.service.v1.GetByIDRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.GetByIDRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.GetByIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.GetByIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.GetByIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.GetByIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    service: (f = msg.getService()) && proto.scoretrak.service.v1.Service.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.GetByIDResponse}
 */
proto.scoretrak.service.v1.GetByIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.GetByIDResponse;
  return proto.scoretrak.service.v1.GetByIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.GetByIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.GetByIDResponse}
 */
proto.scoretrak.service.v1.GetByIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.service.v1.Service;
      reader.readMessage(value,proto.scoretrak.service.v1.Service.deserializeBinaryFromReader);
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.GetByIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.GetByIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.GetByIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.GetByIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.scoretrak.service.v1.Service.serializeBinaryToWriter
    );
  }
};


/**
 * optional Service service = 1;
 * @return {?proto.scoretrak.service.v1.Service}
 */
proto.scoretrak.service.v1.GetByIDResponse.prototype.getService = function() {
  return /** @type{?proto.scoretrak.service.v1.Service} */ (
    jspb.Message.getWrapperField(this, proto.scoretrak.service.v1.Service, 1));
};


/**
 * @param {?proto.scoretrak.service.v1.Service|undefined} value
 * @return {!proto.scoretrak.service.v1.GetByIDResponse} returns this
*/
proto.scoretrak.service.v1.GetByIDResponse.prototype.setService = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.GetByIDResponse} returns this
 */
proto.scoretrak.service.v1.GetByIDResponse.prototype.clearService = function() {
  return this.setService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.GetByIDResponse.prototype.hasService = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.DeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.DeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.DeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.DeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.DeleteRequest}
 */
proto.scoretrak.service.v1.DeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.DeleteRequest;
  return proto.scoretrak.service.v1.DeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.DeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.DeleteRequest}
 */
proto.scoretrak.service.v1.DeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.DeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.DeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.DeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.DeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service.v1.DeleteRequest.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service.v1.DeleteRequest} returns this
*/
proto.scoretrak.service.v1.DeleteRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.DeleteRequest} returns this
 */
proto.scoretrak.service.v1.DeleteRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.DeleteRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.DeleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.DeleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.DeleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.DeleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.DeleteResponse}
 */
proto.scoretrak.service.v1.DeleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.DeleteResponse;
  return proto.scoretrak.service.v1.DeleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.DeleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.DeleteResponse}
 */
proto.scoretrak.service.v1.DeleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.DeleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.DeleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.DeleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.DeleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.service.v1.StoreRequest.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.StoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.StoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.StoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.StoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    proto.scoretrak.service.v1.Service.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.StoreRequest}
 */
proto.scoretrak.service.v1.StoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.StoreRequest;
  return proto.scoretrak.service.v1.StoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.StoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.StoreRequest}
 */
proto.scoretrak.service.v1.StoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.service.v1.Service;
      reader.readMessage(value,proto.scoretrak.service.v1.Service.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.StoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.StoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.StoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.StoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.scoretrak.service.v1.Service.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Service services = 1;
 * @return {!Array<!proto.scoretrak.service.v1.Service>}
 */
proto.scoretrak.service.v1.StoreRequest.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.scoretrak.service.v1.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scoretrak.service.v1.Service, 1));
};


/**
 * @param {!Array<!proto.scoretrak.service.v1.Service>} value
 * @return {!proto.scoretrak.service.v1.StoreRequest} returns this
*/
proto.scoretrak.service.v1.StoreRequest.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scoretrak.service.v1.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.service.v1.Service}
 */
proto.scoretrak.service.v1.StoreRequest.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scoretrak.service.v1.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.service.v1.StoreRequest} returns this
 */
proto.scoretrak.service.v1.StoreRequest.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.service.v1.StoreResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.StoreResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.StoreResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.StoreResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.StoreResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    idsList: jspb.Message.toObjectList(msg.getIdsList(),
    scoretrak_proto_v1_uuid_pb.UUID.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.StoreResponse}
 */
proto.scoretrak.service.v1.StoreResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.StoreResponse;
  return proto.scoretrak.service.v1.StoreResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.StoreResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.StoreResponse}
 */
proto.scoretrak.service.v1.StoreResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.addIds(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.StoreResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.StoreResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.StoreResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.StoreResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getIdsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * repeated scoretrak.proto.v1.UUID ids = 1;
 * @return {!Array<!proto.scoretrak.proto.v1.UUID>}
 */
proto.scoretrak.service.v1.StoreResponse.prototype.getIdsList = function() {
  return /** @type{!Array<!proto.scoretrak.proto.v1.UUID>} */ (
    jspb.Message.getRepeatedWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {!Array<!proto.scoretrak.proto.v1.UUID>} value
 * @return {!proto.scoretrak.service.v1.StoreResponse} returns this
*/
proto.scoretrak.service.v1.StoreResponse.prototype.setIdsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scoretrak.proto.v1.UUID=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service.v1.StoreResponse.prototype.addIds = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scoretrak.proto.v1.UUID, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.service.v1.StoreResponse} returns this
 */
proto.scoretrak.service.v1.StoreResponse.prototype.clearIdsList = function() {
  return this.setIdsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.UpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.UpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.UpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.UpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    service: (f = msg.getService()) && proto.scoretrak.service.v1.Service.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.UpdateRequest}
 */
proto.scoretrak.service.v1.UpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.UpdateRequest;
  return proto.scoretrak.service.v1.UpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.UpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.UpdateRequest}
 */
proto.scoretrak.service.v1.UpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.service.v1.Service;
      reader.readMessage(value,proto.scoretrak.service.v1.Service.deserializeBinaryFromReader);
      msg.setService(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.UpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.UpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.UpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.UpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getService();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.scoretrak.service.v1.Service.serializeBinaryToWriter
    );
  }
};


/**
 * optional Service service = 1;
 * @return {?proto.scoretrak.service.v1.Service}
 */
proto.scoretrak.service.v1.UpdateRequest.prototype.getService = function() {
  return /** @type{?proto.scoretrak.service.v1.Service} */ (
    jspb.Message.getWrapperField(this, proto.scoretrak.service.v1.Service, 1));
};


/**
 * @param {?proto.scoretrak.service.v1.Service|undefined} value
 * @return {!proto.scoretrak.service.v1.UpdateRequest} returns this
*/
proto.scoretrak.service.v1.UpdateRequest.prototype.setService = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.UpdateRequest} returns this
 */
proto.scoretrak.service.v1.UpdateRequest.prototype.clearService = function() {
  return this.setService(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.UpdateRequest.prototype.hasService = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.UpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.UpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.UpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.UpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.UpdateResponse}
 */
proto.scoretrak.service.v1.UpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.UpdateResponse;
  return proto.scoretrak.service.v1.UpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.UpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.UpdateResponse}
 */
proto.scoretrak.service.v1.UpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.UpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.UpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.UpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.UpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.TestServiceRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.TestServiceRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.TestServiceRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.TestServiceRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.TestServiceRequest}
 */
proto.scoretrak.service.v1.TestServiceRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.TestServiceRequest;
  return proto.scoretrak.service.v1.TestServiceRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.TestServiceRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.TestServiceRequest}
 */
proto.scoretrak.service.v1.TestServiceRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.TestServiceRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.TestServiceRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.TestServiceRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.TestServiceRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service.v1.TestServiceRequest.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service.v1.TestServiceRequest} returns this
*/
proto.scoretrak.service.v1.TestServiceRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.TestServiceRequest} returns this
 */
proto.scoretrak.service.v1.TestServiceRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.TestServiceRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service.v1.TestServiceResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service.v1.TestServiceResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service.v1.TestServiceResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.TestServiceResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    check: (f = msg.getCheck()) && scoretrak_check_v1_check_pb.Check.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service.v1.TestServiceResponse}
 */
proto.scoretrak.service.v1.TestServiceResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service.v1.TestServiceResponse;
  return proto.scoretrak.service.v1.TestServiceResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service.v1.TestServiceResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service.v1.TestServiceResponse}
 */
proto.scoretrak.service.v1.TestServiceResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_check_v1_check_pb.Check;
      reader.readMessage(value,scoretrak_check_v1_check_pb.Check.deserializeBinaryFromReader);
      msg.setCheck(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service.v1.TestServiceResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service.v1.TestServiceResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service.v1.TestServiceResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service.v1.TestServiceResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheck();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_check_v1_check_pb.Check.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.check.v1.Check check = 1;
 * @return {?proto.scoretrak.check.v1.Check}
 */
proto.scoretrak.service.v1.TestServiceResponse.prototype.getCheck = function() {
  return /** @type{?proto.scoretrak.check.v1.Check} */ (
    jspb.Message.getWrapperField(this, scoretrak_check_v1_check_pb.Check, 1));
};


/**
 * @param {?proto.scoretrak.check.v1.Check|undefined} value
 * @return {!proto.scoretrak.service.v1.TestServiceResponse} returns this
*/
proto.scoretrak.service.v1.TestServiceResponse.prototype.setCheck = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service.v1.TestServiceResponse} returns this
 */
proto.scoretrak.service.v1.TestServiceResponse.prototype.clearCheck = function() {
  return this.setCheck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service.v1.TestServiceResponse.prototype.hasCheck = function() {
  return jspb.Message.getField(this, 1) != null;
};


goog.object.extend(exports, proto.scoretrak.service.v1);
