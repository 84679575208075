// source: scoretrak/service_group/v1/service_group.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var scoretrak_proto_v1_uuid_pb = require('../../../scoretrak/proto/v1/uuid_pb.js');
goog.object.extend(proto, scoretrak_proto_v1_uuid_pb);
var scoretrak_service_v1_service_pb = require('../../../scoretrak/service/v1/service_pb.js');
goog.object.extend(proto, scoretrak_service_v1_service_pb);
goog.exportSymbol('proto.scoretrak.service_group.v1.DeleteRequest', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.DeleteResponse', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.GetAllRequest', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.GetAllResponse', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.GetByIDRequest', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.GetByIDResponse', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.RedeployRequest', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.RedeployResponse', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.ServiceGroup', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.StoreRequest', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.StoreResponse', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.UpdateRequest', null, global);
goog.exportSymbol('proto.scoretrak.service_group.v1.UpdateResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.ServiceGroup = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.service_group.v1.ServiceGroup.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.service_group.v1.ServiceGroup, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.ServiceGroup.displayName = 'proto.scoretrak.service_group.v1.ServiceGroup';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.GetAllRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.GetAllRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.GetAllRequest.displayName = 'proto.scoretrak.service_group.v1.GetAllRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.GetAllResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.service_group.v1.GetAllResponse.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.service_group.v1.GetAllResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.GetAllResponse.displayName = 'proto.scoretrak.service_group.v1.GetAllResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.GetByIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.GetByIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.GetByIDRequest.displayName = 'proto.scoretrak.service_group.v1.GetByIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.GetByIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.GetByIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.GetByIDResponse.displayName = 'proto.scoretrak.service_group.v1.GetByIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.DeleteRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.DeleteRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.DeleteRequest.displayName = 'proto.scoretrak.service_group.v1.DeleteRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.DeleteResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.DeleteResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.DeleteResponse.displayName = 'proto.scoretrak.service_group.v1.DeleteResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.StoreRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.StoreRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.StoreRequest.displayName = 'proto.scoretrak.service_group.v1.StoreRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.StoreResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.StoreResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.StoreResponse.displayName = 'proto.scoretrak.service_group.v1.StoreResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.UpdateRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.UpdateRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.UpdateRequest.displayName = 'proto.scoretrak.service_group.v1.UpdateRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.UpdateResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.UpdateResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.UpdateResponse.displayName = 'proto.scoretrak.service_group.v1.UpdateResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.RedeployRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.RedeployRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.RedeployRequest.displayName = 'proto.scoretrak.service_group.v1.RedeployRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.service_group.v1.RedeployResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.service_group.v1.RedeployResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.service_group.v1.RedeployResponse.displayName = 'proto.scoretrak.service_group.v1.RedeployResponse';
}

/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.service_group.v1.ServiceGroup.repeatedFields_ = [7];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.ServiceGroup.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.ServiceGroup} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.ServiceGroup.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    displayName: jspb.Message.getFieldWithDefault(msg, 3, ""),
    enabled: (f = msg.getEnabled()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f),
    skipHelper: jspb.Message.getBooleanFieldWithDefault(msg, 5, false),
    label: jspb.Message.getFieldWithDefault(msg, 6, ""),
    servicesList: jspb.Message.toObjectList(msg.getServicesList(),
    scoretrak_service_v1_service_pb.Service.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup}
 */
proto.scoretrak.service_group.v1.ServiceGroup.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.ServiceGroup;
  return proto.scoretrak.service_group.v1.ServiceGroup.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.ServiceGroup} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup}
 */
proto.scoretrak.service_group.v1.ServiceGroup.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setDisplayName(value);
      break;
    case 4:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setEnabled(value);
      break;
    case 5:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setSkipHelper(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setLabel(value);
      break;
    case 7:
      var value = new scoretrak_service_v1_service_pb.Service;
      reader.readMessage(value,scoretrak_service_v1_service_pb.Service.deserializeBinaryFromReader);
      msg.addServices(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.ServiceGroup.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.ServiceGroup} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.ServiceGroup.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getDisplayName();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getEnabled();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
  f = message.getSkipHelper();
  if (f) {
    writer.writeBool(
      5,
      f
    );
  }
  f = message.getLabel();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getServicesList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      7,
      f,
      scoretrak_service_v1_service_pb.Service.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
*/
proto.scoretrak.service_group.v1.ServiceGroup.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string name = 2;
 * @return {string}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string display_name = 3;
 * @return {string}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.getDisplayName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.setDisplayName = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional google.protobuf.BoolValue enabled = 4;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.getEnabled = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 4));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
*/
proto.scoretrak.service_group.v1.ServiceGroup.prototype.setEnabled = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.clearEnabled = function() {
  return this.setEnabled(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.hasEnabled = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional bool skip_helper = 5;
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.getSkipHelper = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 5, false));
};


/**
 * @param {boolean} value
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.setSkipHelper = function(value) {
  return jspb.Message.setProto3BooleanField(this, 5, value);
};


/**
 * optional string label = 6;
 * @return {string}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.getLabel = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.setLabel = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * repeated scoretrak.service.v1.Service services = 7;
 * @return {!Array<!proto.scoretrak.service.v1.Service>}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.getServicesList = function() {
  return /** @type{!Array<!proto.scoretrak.service.v1.Service>} */ (
    jspb.Message.getRepeatedWrapperField(this, scoretrak_service_v1_service_pb.Service, 7));
};


/**
 * @param {!Array<!proto.scoretrak.service.v1.Service>} value
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
*/
proto.scoretrak.service_group.v1.ServiceGroup.prototype.setServicesList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 7, value);
};


/**
 * @param {!proto.scoretrak.service.v1.Service=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.service.v1.Service}
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.addServices = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 7, opt_value, proto.scoretrak.service.v1.Service, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup} returns this
 */
proto.scoretrak.service_group.v1.ServiceGroup.prototype.clearServicesList = function() {
  return this.setServicesList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.GetAllRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.GetAllRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.GetAllRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.GetAllRequest.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.GetAllRequest}
 */
proto.scoretrak.service_group.v1.GetAllRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.GetAllRequest;
  return proto.scoretrak.service_group.v1.GetAllRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.GetAllRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.GetAllRequest}
 */
proto.scoretrak.service_group.v1.GetAllRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.GetAllRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.GetAllRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.GetAllRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.GetAllRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.service_group.v1.GetAllResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.GetAllResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.GetAllResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.GetAllResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.GetAllResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceGroupsList: jspb.Message.toObjectList(msg.getServiceGroupsList(),
    proto.scoretrak.service_group.v1.ServiceGroup.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.GetAllResponse}
 */
proto.scoretrak.service_group.v1.GetAllResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.GetAllResponse;
  return proto.scoretrak.service_group.v1.GetAllResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.GetAllResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.GetAllResponse}
 */
proto.scoretrak.service_group.v1.GetAllResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.service_group.v1.ServiceGroup;
      reader.readMessage(value,proto.scoretrak.service_group.v1.ServiceGroup.deserializeBinaryFromReader);
      msg.addServiceGroups(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.GetAllResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.GetAllResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.GetAllResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.GetAllResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceGroupsList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.scoretrak.service_group.v1.ServiceGroup.serializeBinaryToWriter
    );
  }
};


/**
 * repeated ServiceGroup service_groups = 1;
 * @return {!Array<!proto.scoretrak.service_group.v1.ServiceGroup>}
 */
proto.scoretrak.service_group.v1.GetAllResponse.prototype.getServiceGroupsList = function() {
  return /** @type{!Array<!proto.scoretrak.service_group.v1.ServiceGroup>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scoretrak.service_group.v1.ServiceGroup, 1));
};


/**
 * @param {!Array<!proto.scoretrak.service_group.v1.ServiceGroup>} value
 * @return {!proto.scoretrak.service_group.v1.GetAllResponse} returns this
*/
proto.scoretrak.service_group.v1.GetAllResponse.prototype.setServiceGroupsList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scoretrak.service_group.v1.ServiceGroup=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.service_group.v1.ServiceGroup}
 */
proto.scoretrak.service_group.v1.GetAllResponse.prototype.addServiceGroups = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scoretrak.service_group.v1.ServiceGroup, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.service_group.v1.GetAllResponse} returns this
 */
proto.scoretrak.service_group.v1.GetAllResponse.prototype.clearServiceGroupsList = function() {
  return this.setServiceGroupsList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.GetByIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.GetByIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.GetByIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.GetByIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.GetByIDRequest}
 */
proto.scoretrak.service_group.v1.GetByIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.GetByIDRequest;
  return proto.scoretrak.service_group.v1.GetByIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.GetByIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.GetByIDRequest}
 */
proto.scoretrak.service_group.v1.GetByIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.GetByIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.GetByIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.GetByIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.GetByIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service_group.v1.GetByIDRequest.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service_group.v1.GetByIDRequest} returns this
*/
proto.scoretrak.service_group.v1.GetByIDRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service_group.v1.GetByIDRequest} returns this
 */
proto.scoretrak.service_group.v1.GetByIDRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.GetByIDRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.GetByIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.GetByIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.GetByIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.GetByIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceGroup: (f = msg.getServiceGroup()) && proto.scoretrak.service_group.v1.ServiceGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.GetByIDResponse}
 */
proto.scoretrak.service_group.v1.GetByIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.GetByIDResponse;
  return proto.scoretrak.service_group.v1.GetByIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.GetByIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.GetByIDResponse}
 */
proto.scoretrak.service_group.v1.GetByIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.service_group.v1.ServiceGroup;
      reader.readMessage(value,proto.scoretrak.service_group.v1.ServiceGroup.deserializeBinaryFromReader);
      msg.setServiceGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.GetByIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.GetByIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.GetByIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.GetByIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.scoretrak.service_group.v1.ServiceGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServiceGroup service_group = 1;
 * @return {?proto.scoretrak.service_group.v1.ServiceGroup}
 */
proto.scoretrak.service_group.v1.GetByIDResponse.prototype.getServiceGroup = function() {
  return /** @type{?proto.scoretrak.service_group.v1.ServiceGroup} */ (
    jspb.Message.getWrapperField(this, proto.scoretrak.service_group.v1.ServiceGroup, 1));
};


/**
 * @param {?proto.scoretrak.service_group.v1.ServiceGroup|undefined} value
 * @return {!proto.scoretrak.service_group.v1.GetByIDResponse} returns this
*/
proto.scoretrak.service_group.v1.GetByIDResponse.prototype.setServiceGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service_group.v1.GetByIDResponse} returns this
 */
proto.scoretrak.service_group.v1.GetByIDResponse.prototype.clearServiceGroup = function() {
  return this.setServiceGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.GetByIDResponse.prototype.hasServiceGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.DeleteRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.DeleteRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.DeleteRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.DeleteRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.DeleteRequest}
 */
proto.scoretrak.service_group.v1.DeleteRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.DeleteRequest;
  return proto.scoretrak.service_group.v1.DeleteRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.DeleteRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.DeleteRequest}
 */
proto.scoretrak.service_group.v1.DeleteRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.DeleteRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.DeleteRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.DeleteRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.DeleteRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service_group.v1.DeleteRequest.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service_group.v1.DeleteRequest} returns this
*/
proto.scoretrak.service_group.v1.DeleteRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service_group.v1.DeleteRequest} returns this
 */
proto.scoretrak.service_group.v1.DeleteRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.DeleteRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.DeleteResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.DeleteResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.DeleteResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.DeleteResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.DeleteResponse}
 */
proto.scoretrak.service_group.v1.DeleteResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.DeleteResponse;
  return proto.scoretrak.service_group.v1.DeleteResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.DeleteResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.DeleteResponse}
 */
proto.scoretrak.service_group.v1.DeleteResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.DeleteResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.DeleteResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.DeleteResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.DeleteResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.StoreRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.StoreRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.StoreRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.StoreRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceGroup: (f = msg.getServiceGroup()) && proto.scoretrak.service_group.v1.ServiceGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.StoreRequest}
 */
proto.scoretrak.service_group.v1.StoreRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.StoreRequest;
  return proto.scoretrak.service_group.v1.StoreRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.StoreRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.StoreRequest}
 */
proto.scoretrak.service_group.v1.StoreRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.service_group.v1.ServiceGroup;
      reader.readMessage(value,proto.scoretrak.service_group.v1.ServiceGroup.deserializeBinaryFromReader);
      msg.setServiceGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.StoreRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.StoreRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.StoreRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.StoreRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.scoretrak.service_group.v1.ServiceGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServiceGroup service_group = 1;
 * @return {?proto.scoretrak.service_group.v1.ServiceGroup}
 */
proto.scoretrak.service_group.v1.StoreRequest.prototype.getServiceGroup = function() {
  return /** @type{?proto.scoretrak.service_group.v1.ServiceGroup} */ (
    jspb.Message.getWrapperField(this, proto.scoretrak.service_group.v1.ServiceGroup, 1));
};


/**
 * @param {?proto.scoretrak.service_group.v1.ServiceGroup|undefined} value
 * @return {!proto.scoretrak.service_group.v1.StoreRequest} returns this
*/
proto.scoretrak.service_group.v1.StoreRequest.prototype.setServiceGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service_group.v1.StoreRequest} returns this
 */
proto.scoretrak.service_group.v1.StoreRequest.prototype.clearServiceGroup = function() {
  return this.setServiceGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.StoreRequest.prototype.hasServiceGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.StoreResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.StoreResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.StoreResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.StoreResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.StoreResponse}
 */
proto.scoretrak.service_group.v1.StoreResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.StoreResponse;
  return proto.scoretrak.service_group.v1.StoreResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.StoreResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.StoreResponse}
 */
proto.scoretrak.service_group.v1.StoreResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.StoreResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.StoreResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.StoreResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.StoreResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service_group.v1.StoreResponse.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service_group.v1.StoreResponse} returns this
*/
proto.scoretrak.service_group.v1.StoreResponse.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service_group.v1.StoreResponse} returns this
 */
proto.scoretrak.service_group.v1.StoreResponse.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.StoreResponse.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.UpdateRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.UpdateRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.UpdateRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.UpdateRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceGroup: (f = msg.getServiceGroup()) && proto.scoretrak.service_group.v1.ServiceGroup.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.UpdateRequest}
 */
proto.scoretrak.service_group.v1.UpdateRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.UpdateRequest;
  return proto.scoretrak.service_group.v1.UpdateRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.UpdateRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.UpdateRequest}
 */
proto.scoretrak.service_group.v1.UpdateRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.service_group.v1.ServiceGroup;
      reader.readMessage(value,proto.scoretrak.service_group.v1.ServiceGroup.deserializeBinaryFromReader);
      msg.setServiceGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.UpdateRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.UpdateRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.UpdateRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.UpdateRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceGroup();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.scoretrak.service_group.v1.ServiceGroup.serializeBinaryToWriter
    );
  }
};


/**
 * optional ServiceGroup service_group = 1;
 * @return {?proto.scoretrak.service_group.v1.ServiceGroup}
 */
proto.scoretrak.service_group.v1.UpdateRequest.prototype.getServiceGroup = function() {
  return /** @type{?proto.scoretrak.service_group.v1.ServiceGroup} */ (
    jspb.Message.getWrapperField(this, proto.scoretrak.service_group.v1.ServiceGroup, 1));
};


/**
 * @param {?proto.scoretrak.service_group.v1.ServiceGroup|undefined} value
 * @return {!proto.scoretrak.service_group.v1.UpdateRequest} returns this
*/
proto.scoretrak.service_group.v1.UpdateRequest.prototype.setServiceGroup = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service_group.v1.UpdateRequest} returns this
 */
proto.scoretrak.service_group.v1.UpdateRequest.prototype.clearServiceGroup = function() {
  return this.setServiceGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.UpdateRequest.prototype.hasServiceGroup = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.UpdateResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.UpdateResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.UpdateResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.UpdateResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.UpdateResponse}
 */
proto.scoretrak.service_group.v1.UpdateResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.UpdateResponse;
  return proto.scoretrak.service_group.v1.UpdateResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.UpdateResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.UpdateResponse}
 */
proto.scoretrak.service_group.v1.UpdateResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.UpdateResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.UpdateResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.UpdateResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.UpdateResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.RedeployRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.RedeployRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.RedeployRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.RedeployRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: (f = msg.getId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.RedeployRequest}
 */
proto.scoretrak.service_group.v1.RedeployRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.RedeployRequest;
  return proto.scoretrak.service_group.v1.RedeployRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.RedeployRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.RedeployRequest}
 */
proto.scoretrak.service_group.v1.RedeployRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.RedeployRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.RedeployRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.RedeployRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.RedeployRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.service_group.v1.RedeployRequest.prototype.getId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.service_group.v1.RedeployRequest} returns this
*/
proto.scoretrak.service_group.v1.RedeployRequest.prototype.setId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.service_group.v1.RedeployRequest} returns this
 */
proto.scoretrak.service_group.v1.RedeployRequest.prototype.clearId = function() {
  return this.setId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.service_group.v1.RedeployRequest.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.service_group.v1.RedeployResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.service_group.v1.RedeployResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.service_group.v1.RedeployResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.RedeployResponse.toObject = function(includeInstance, msg) {
  var f, obj = {

  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.service_group.v1.RedeployResponse}
 */
proto.scoretrak.service_group.v1.RedeployResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.service_group.v1.RedeployResponse;
  return proto.scoretrak.service_group.v1.RedeployResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.service_group.v1.RedeployResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.service_group.v1.RedeployResponse}
 */
proto.scoretrak.service_group.v1.RedeployResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.service_group.v1.RedeployResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.service_group.v1.RedeployResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.service_group.v1.RedeployResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.service_group.v1.RedeployResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
};


goog.object.extend(exports, proto.scoretrak.service_group.v1);
