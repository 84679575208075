// source: scoretrak/check/v1/check.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var google_protobuf_wrappers_pb = require('google-protobuf/google/protobuf/wrappers_pb.js');
goog.object.extend(proto, google_protobuf_wrappers_pb);
var scoretrak_proto_v1_uuid_pb = require('../../../scoretrak/proto/v1/uuid_pb.js');
goog.object.extend(proto, scoretrak_proto_v1_uuid_pb);
goog.exportSymbol('proto.scoretrak.check.v1.Check', null, global);
goog.exportSymbol('proto.scoretrak.check.v1.GetAllByRoundIDRequest', null, global);
goog.exportSymbol('proto.scoretrak.check.v1.GetAllByRoundIDResponse', null, global);
goog.exportSymbol('proto.scoretrak.check.v1.GetAllByServiceIDRequest', null, global);
goog.exportSymbol('proto.scoretrak.check.v1.GetAllByServiceIDResponse', null, global);
goog.exportSymbol('proto.scoretrak.check.v1.GetByRoundServiceIDRequest', null, global);
goog.exportSymbol('proto.scoretrak.check.v1.GetByRoundServiceIDResponse', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.check.v1.Check = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.check.v1.Check, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.check.v1.Check.displayName = 'proto.scoretrak.check.v1.Check';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.check.v1.GetAllByRoundIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.check.v1.GetAllByRoundIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.check.v1.GetAllByRoundIDRequest.displayName = 'proto.scoretrak.check.v1.GetAllByRoundIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.check.v1.GetAllByRoundIDResponse.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.check.v1.GetAllByRoundIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.check.v1.GetAllByRoundIDResponse.displayName = 'proto.scoretrak.check.v1.GetAllByRoundIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.check.v1.GetByRoundServiceIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.check.v1.GetByRoundServiceIDRequest.displayName = 'proto.scoretrak.check.v1.GetByRoundServiceIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.check.v1.GetByRoundServiceIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.check.v1.GetByRoundServiceIDResponse.displayName = 'proto.scoretrak.check.v1.GetByRoundServiceIDResponse';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.scoretrak.check.v1.GetAllByServiceIDRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.check.v1.GetAllByServiceIDRequest.displayName = 'proto.scoretrak.check.v1.GetAllByServiceIDRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.scoretrak.check.v1.GetAllByServiceIDResponse.repeatedFields_, null);
};
goog.inherits(proto.scoretrak.check.v1.GetAllByServiceIDResponse, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.scoretrak.check.v1.GetAllByServiceIDResponse.displayName = 'proto.scoretrak.check.v1.GetAllByServiceIDResponse';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.check.v1.Check.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.check.v1.Check.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.check.v1.Check} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.Check.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: (f = msg.getServiceId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f),
    roundId: jspb.Message.getFieldWithDefault(msg, 2, 0),
    log: jspb.Message.getFieldWithDefault(msg, 3, ""),
    err: jspb.Message.getFieldWithDefault(msg, 4, ""),
    passed: (f = msg.getPassed()) && google_protobuf_wrappers_pb.BoolValue.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.check.v1.Check}
 */
proto.scoretrak.check.v1.Check.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.check.v1.Check;
  return proto.scoretrak.check.v1.Check.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.check.v1.Check} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.check.v1.Check}
 */
proto.scoretrak.check.v1.Check.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRoundId(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLog(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setErr(value);
      break;
    case 5:
      var value = new google_protobuf_wrappers_pb.BoolValue;
      reader.readMessage(value,google_protobuf_wrappers_pb.BoolValue.deserializeBinaryFromReader);
      msg.setPassed(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.check.v1.Check.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.check.v1.Check.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.check.v1.Check} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.Check.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
  f = message.getRoundId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
  f = message.getLog();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getErr();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getPassed();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      google_protobuf_wrappers_pb.BoolValue.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID service_id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.check.v1.Check.prototype.getServiceId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.check.v1.Check} returns this
*/
proto.scoretrak.check.v1.Check.prototype.setServiceId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.check.v1.Check} returns this
 */
proto.scoretrak.check.v1.Check.prototype.clearServiceId = function() {
  return this.setServiceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.check.v1.Check.prototype.hasServiceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 round_id = 2;
 * @return {number}
 */
proto.scoretrak.check.v1.Check.prototype.getRoundId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.scoretrak.check.v1.Check} returns this
 */
proto.scoretrak.check.v1.Check.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};


/**
 * optional string log = 3;
 * @return {string}
 */
proto.scoretrak.check.v1.Check.prototype.getLog = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.scoretrak.check.v1.Check} returns this
 */
proto.scoretrak.check.v1.Check.prototype.setLog = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string err = 4;
 * @return {string}
 */
proto.scoretrak.check.v1.Check.prototype.getErr = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.scoretrak.check.v1.Check} returns this
 */
proto.scoretrak.check.v1.Check.prototype.setErr = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional google.protobuf.BoolValue passed = 5;
 * @return {?proto.google.protobuf.BoolValue}
 */
proto.scoretrak.check.v1.Check.prototype.getPassed = function() {
  return /** @type{?proto.google.protobuf.BoolValue} */ (
    jspb.Message.getWrapperField(this, google_protobuf_wrappers_pb.BoolValue, 5));
};


/**
 * @param {?proto.google.protobuf.BoolValue|undefined} value
 * @return {!proto.scoretrak.check.v1.Check} returns this
*/
proto.scoretrak.check.v1.Check.prototype.setPassed = function(value) {
  return jspb.Message.setWrapperField(this, 5, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.check.v1.Check} returns this
 */
proto.scoretrak.check.v1.Check.prototype.clearPassed = function() {
  return this.setPassed(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.check.v1.Check.prototype.hasPassed = function() {
  return jspb.Message.getField(this, 5) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.check.v1.GetAllByRoundIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.check.v1.GetAllByRoundIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.check.v1.GetAllByRoundIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetAllByRoundIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    roundId: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.check.v1.GetAllByRoundIDRequest}
 */
proto.scoretrak.check.v1.GetAllByRoundIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.check.v1.GetAllByRoundIDRequest;
  return proto.scoretrak.check.v1.GetAllByRoundIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.check.v1.GetAllByRoundIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.check.v1.GetAllByRoundIDRequest}
 */
proto.scoretrak.check.v1.GetAllByRoundIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRoundId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.check.v1.GetAllByRoundIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.check.v1.GetAllByRoundIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.check.v1.GetAllByRoundIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetAllByRoundIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getRoundId();
  if (f !== 0) {
    writer.writeUint64(
      1,
      f
    );
  }
};


/**
 * optional uint64 round_id = 1;
 * @return {number}
 */
proto.scoretrak.check.v1.GetAllByRoundIDRequest.prototype.getRoundId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {number} value
 * @return {!proto.scoretrak.check.v1.GetAllByRoundIDRequest} returns this
 */
proto.scoretrak.check.v1.GetAllByRoundIDRequest.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3IntField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.check.v1.GetAllByRoundIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.check.v1.GetAllByRoundIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    checksList: jspb.Message.toObjectList(msg.getChecksList(),
    proto.scoretrak.check.v1.Check.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.check.v1.GetAllByRoundIDResponse}
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.check.v1.GetAllByRoundIDResponse;
  return proto.scoretrak.check.v1.GetAllByRoundIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.check.v1.GetAllByRoundIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.check.v1.GetAllByRoundIDResponse}
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.check.v1.Check;
      reader.readMessage(value,proto.scoretrak.check.v1.Check.deserializeBinaryFromReader);
      msg.addChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.check.v1.GetAllByRoundIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.check.v1.GetAllByRoundIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.scoretrak.check.v1.Check.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Check checks = 1;
 * @return {!Array<!proto.scoretrak.check.v1.Check>}
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.prototype.getChecksList = function() {
  return /** @type{!Array<!proto.scoretrak.check.v1.Check>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scoretrak.check.v1.Check, 1));
};


/**
 * @param {!Array<!proto.scoretrak.check.v1.Check>} value
 * @return {!proto.scoretrak.check.v1.GetAllByRoundIDResponse} returns this
*/
proto.scoretrak.check.v1.GetAllByRoundIDResponse.prototype.setChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scoretrak.check.v1.Check=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.check.v1.Check}
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.prototype.addChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scoretrak.check.v1.Check, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.check.v1.GetAllByRoundIDResponse} returns this
 */
proto.scoretrak.check.v1.GetAllByRoundIDResponse.prototype.clearChecksList = function() {
  return this.setChecksList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.check.v1.GetByRoundServiceIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.check.v1.GetByRoundServiceIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: (f = msg.getServiceId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f),
    roundId: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.check.v1.GetByRoundServiceIDRequest}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.check.v1.GetByRoundServiceIDRequest;
  return proto.scoretrak.check.v1.GetByRoundServiceIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.check.v1.GetByRoundServiceIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.check.v1.GetByRoundServiceIDRequest}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setServiceId(value);
      break;
    case 2:
      var value = /** @type {number} */ (reader.readUint64());
      msg.setRoundId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.check.v1.GetByRoundServiceIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.check.v1.GetByRoundServiceIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
  f = message.getRoundId();
  if (f !== 0) {
    writer.writeUint64(
      2,
      f
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID service_id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.prototype.getServiceId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.check.v1.GetByRoundServiceIDRequest} returns this
*/
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.prototype.setServiceId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.check.v1.GetByRoundServiceIDRequest} returns this
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.prototype.clearServiceId = function() {
  return this.setServiceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.prototype.hasServiceId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional uint64 round_id = 2;
 * @return {number}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.prototype.getRoundId = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {number} value
 * @return {!proto.scoretrak.check.v1.GetByRoundServiceIDRequest} returns this
 */
proto.scoretrak.check.v1.GetByRoundServiceIDRequest.prototype.setRoundId = function(value) {
  return jspb.Message.setProto3IntField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.check.v1.GetByRoundServiceIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.check.v1.GetByRoundServiceIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    check: (f = msg.getCheck()) && proto.scoretrak.check.v1.Check.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.check.v1.GetByRoundServiceIDResponse}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.check.v1.GetByRoundServiceIDResponse;
  return proto.scoretrak.check.v1.GetByRoundServiceIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.check.v1.GetByRoundServiceIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.check.v1.GetByRoundServiceIDResponse}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.check.v1.Check;
      reader.readMessage(value,proto.scoretrak.check.v1.Check.deserializeBinaryFromReader);
      msg.setCheck(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.check.v1.GetByRoundServiceIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.check.v1.GetByRoundServiceIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCheck();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.scoretrak.check.v1.Check.serializeBinaryToWriter
    );
  }
};


/**
 * optional Check check = 1;
 * @return {?proto.scoretrak.check.v1.Check}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.prototype.getCheck = function() {
  return /** @type{?proto.scoretrak.check.v1.Check} */ (
    jspb.Message.getWrapperField(this, proto.scoretrak.check.v1.Check, 1));
};


/**
 * @param {?proto.scoretrak.check.v1.Check|undefined} value
 * @return {!proto.scoretrak.check.v1.GetByRoundServiceIDResponse} returns this
*/
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.prototype.setCheck = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.check.v1.GetByRoundServiceIDResponse} returns this
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.prototype.clearCheck = function() {
  return this.setCheck(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.check.v1.GetByRoundServiceIDResponse.prototype.hasCheck = function() {
  return jspb.Message.getField(this, 1) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.check.v1.GetAllByServiceIDRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.check.v1.GetAllByServiceIDRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    serviceId: (f = msg.getServiceId()) && scoretrak_proto_v1_uuid_pb.UUID.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.check.v1.GetAllByServiceIDRequest}
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.check.v1.GetAllByServiceIDRequest;
  return proto.scoretrak.check.v1.GetAllByServiceIDRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.check.v1.GetAllByServiceIDRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.check.v1.GetAllByServiceIDRequest}
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new scoretrak_proto_v1_uuid_pb.UUID;
      reader.readMessage(value,scoretrak_proto_v1_uuid_pb.UUID.deserializeBinaryFromReader);
      msg.setServiceId(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.check.v1.GetAllByServiceIDRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.check.v1.GetAllByServiceIDRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getServiceId();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      scoretrak_proto_v1_uuid_pb.UUID.serializeBinaryToWriter
    );
  }
};


/**
 * optional scoretrak.proto.v1.UUID service_id = 1;
 * @return {?proto.scoretrak.proto.v1.UUID}
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest.prototype.getServiceId = function() {
  return /** @type{?proto.scoretrak.proto.v1.UUID} */ (
    jspb.Message.getWrapperField(this, scoretrak_proto_v1_uuid_pb.UUID, 1));
};


/**
 * @param {?proto.scoretrak.proto.v1.UUID|undefined} value
 * @return {!proto.scoretrak.check.v1.GetAllByServiceIDRequest} returns this
*/
proto.scoretrak.check.v1.GetAllByServiceIDRequest.prototype.setServiceId = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.scoretrak.check.v1.GetAllByServiceIDRequest} returns this
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest.prototype.clearServiceId = function() {
  return this.setServiceId(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.scoretrak.check.v1.GetAllByServiceIDRequest.prototype.hasServiceId = function() {
  return jspb.Message.getField(this, 1) != null;
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.prototype.toObject = function(opt_includeInstance) {
  return proto.scoretrak.check.v1.GetAllByServiceIDResponse.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.scoretrak.check.v1.GetAllByServiceIDResponse} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.toObject = function(includeInstance, msg) {
  var f, obj = {
    checksList: jspb.Message.toObjectList(msg.getChecksList(),
    proto.scoretrak.check.v1.Check.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.scoretrak.check.v1.GetAllByServiceIDResponse}
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.scoretrak.check.v1.GetAllByServiceIDResponse;
  return proto.scoretrak.check.v1.GetAllByServiceIDResponse.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.scoretrak.check.v1.GetAllByServiceIDResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.scoretrak.check.v1.GetAllByServiceIDResponse}
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.scoretrak.check.v1.Check;
      reader.readMessage(value,proto.scoretrak.check.v1.Check.deserializeBinaryFromReader);
      msg.addChecks(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.scoretrak.check.v1.GetAllByServiceIDResponse.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.scoretrak.check.v1.GetAllByServiceIDResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getChecksList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.scoretrak.check.v1.Check.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Check checks = 1;
 * @return {!Array<!proto.scoretrak.check.v1.Check>}
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.prototype.getChecksList = function() {
  return /** @type{!Array<!proto.scoretrak.check.v1.Check>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.scoretrak.check.v1.Check, 1));
};


/**
 * @param {!Array<!proto.scoretrak.check.v1.Check>} value
 * @return {!proto.scoretrak.check.v1.GetAllByServiceIDResponse} returns this
*/
proto.scoretrak.check.v1.GetAllByServiceIDResponse.prototype.setChecksList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.scoretrak.check.v1.Check=} opt_value
 * @param {number=} opt_index
 * @return {!proto.scoretrak.check.v1.Check}
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.prototype.addChecks = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.scoretrak.check.v1.Check, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.scoretrak.check.v1.GetAllByServiceIDResponse} returns this
 */
proto.scoretrak.check.v1.GetAllByServiceIDResponse.prototype.clearChecksList = function() {
  return this.setChecksList([]);
};


goog.object.extend(exports, proto.scoretrak.check.v1);
